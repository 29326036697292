<template>
  <div class="wrapper">
    <NuxtLoadingIndicator color="#FFC115" />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { cmsContentStore } from '#sitis/stores/modules/cms-content-store';
import { appConfigStore as useAppConfigStore } from '#sitis/stores/modules/app-store';
import { catalogStore } from '#sitis/stores/modules/catalog-store';

import {setScripts} from "~/utils/scripts/setScripts";
import setUtmToSessionStorage from "~/utils/forms/setUtmToSessionStorage";

const appConfigStore = useAppConfigStore();
const { isDev } = useRuntimeConfig().public
const { config } = storeToRefs(appConfigStore);

const storeCatalog = catalogStore();
const cmsStore = cmsContentStore();
const { getWishList } = useSitisWish();

const headerCounter = computed(() => {
  const counter = config?.value?.counters?.header || ''

  if (counter !== '') {
    return counter
      .replace(/<!--(.+)-->/gm, '')
      .split('<\/script>')
      .filter((scriptElement: string[]) => scriptElement.length > 0)
      .map((scriptElement: string) => {
        const openScriptTag = scriptElement.match(/<script?(.+)>/gm)?.[0] ?? ''
        const src = scriptElement.match(/(?<=(src="))(.*?)(?=")/g)?.[0] ?? null
        const type = scriptElement.match(/(?<=(type="))(.*?)(?=")/g)?.[0] ?? null
        const children = scriptElement.replace(openScriptTag, '') ?? null
        const async = scriptElement.includes('async') ?? null

        return {
          src,
          type,
          async,
          children
        }
      })
  } else {
    return []
  }
});
const footerCounter = computed(
  () => config?.value?.counters?.footer || ''
);

watchEffect(() => {
  setScripts(footerCounter.value, 'body', isDev)
})

const fetchData = async () => {
  await cmsStore.getMenu();
  await appConfigStore.getConfig();
  await storeCatalog.getCatalog();
};

onServerPrefetch(async () => {
  await fetchData()
});

onMounted(async () => {
  if (Object.keys(cmsStore.menu).length === 0) {
    await fetchData()
  }

  setUtmToSessionStorage()
});

useHead({
  title: 'Жилая и коммерческая недвижимость от застройщика - АСТОН',
  htmlAttrs: {
    lang: 'ru'
  },
  meta: [
    { charset: 'utf-8' },
    { name: 'viewport', content: 'width=device-width' },
    { name: 'description', content: 'description' }
  ],
  link: [
    { rel: 'icon', type: 'image/svg+xml', href: () => config?.value?.favicon || '' }
  ],
  script: headerCounter,
});

onMounted(async () => {
  await getWishList({ extensions: ['characteristics'] });
  console.debug('!!!Build succeeded!!!')
});
</script>

<style lang="scss">
@import "@/assets/scss/media";

.wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.main,
.loader-main {
	min-height: calc(100vh - 60px);
	flex: 1 0 auto;
	background-position: center;
	background-repeat: no-repeat;

	& > * {
		background-color: white;
	}
}

.loader-main {
	background-image: url("~/assets/img/svg/loading/body-loading.svg");
}

.footer-wrapper {
	width: 100%;
	flex: 0 0 auto;
}

.header-wrapper {
	position: relative;
	z-index: 21;
}

.oldSite-redirect {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 0;
	background-color: var(--primary);

	& > a {
		color: var(--white);
		font-size: 20px;
		font-weight: 700;
		text-transform: uppercase;
		transition: opacity .2s linear;

		&:hover {
			opacity: .8;
		}
	}
}

@include media('sm') {
	.oldSite-redirect > a {
		font-size: 14px;
	}
}
</style>
