import validate from "/var/www/astondom-frontend/all/astondom-front-20250331081658/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45trailing_45slash_45global from "/var/www/astondom-frontend/all/astondom-front-20250331081658/middleware/redirect-trailing-slash.global.ts";
import manifest_45route_45rule from "/var/www/astondom-frontend/all/astondom-front-20250331081658/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "layout-setter": () => import("/var/www/astondom-frontend/all/astondom-front-20250331081658/middleware/layout-setter.ts")
}