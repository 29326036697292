import { default as _91_46_46_46cms_93ZNVuHdb1XgMeta } from "/var/www/astondom-frontend/all/astondom-front-20250331081658/pages/[...cms].vue?macro=true";
import { default as _91_46_46_46slug_93IlgpDxEmE8Meta } from "/var/www/astondom-frontend/all/astondom-front-20250331081658/pages/blog/[...slug].vue?macro=true";
import { default as indexYMkHCE98klMeta } from "/var/www/astondom-frontend/all/astondom-front-20250331081658/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93aDGjcaAjcnMeta } from "/var/www/astondom-frontend/all/astondom-front-20250331081658/pages/catalog/[...slug].vue?macro=true";
import { default as temp_catalog_need_32_remove5XJeln5CqpMeta } from "/var/www/astondom-frontend/all/astondom-front-20250331081658/pages/catalog/temp_catalog_need _remove.vue?macro=true";
import { default as _91_46_46_46slug_93k0cMYLvbvqMeta } from "/var/www/astondom-frontend/all/astondom-front-20250331081658/pages/product/[...slug].vue?macro=true";
import { default as indexX6i0yncLh0Meta } from "/var/www/astondom-frontend/all/astondom-front-20250331081658/pages/product/index.vue?macro=true";
import { default as _91_46_46_46slug_93HvJW1aDXKzMeta } from "/var/www/astondom-frontend/all/astondom-front-20250331081658/pages/realty/[...slug].vue?macro=true";
import { default as index3IFQKeLU9eMeta } from "/var/www/astondom-frontend/all/astondom-front-20250331081658/pages/sitemap/index.vue?macro=true";
import { default as indexwM0HUfeP2CMeta } from "/var/www/astondom-frontend/all/astondom-front-20250331081658/pages/wish/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46cms_93ZNVuHdb1XgMeta?.name ?? "cms",
    path: _91_46_46_46cms_93ZNVuHdb1XgMeta?.path ?? "/:cms(.*)*",
    meta: _91_46_46_46cms_93ZNVuHdb1XgMeta || {},
    alias: _91_46_46_46cms_93ZNVuHdb1XgMeta?.alias || [],
    redirect: _91_46_46_46cms_93ZNVuHdb1XgMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20250331081658/pages/[...cms].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93IlgpDxEmE8Meta?.name ?? "blog-slug",
    path: _91_46_46_46slug_93IlgpDxEmE8Meta?.path ?? "/blog/:slug(.*)*",
    meta: _91_46_46_46slug_93IlgpDxEmE8Meta || {},
    alias: _91_46_46_46slug_93IlgpDxEmE8Meta?.alias || [],
    redirect: _91_46_46_46slug_93IlgpDxEmE8Meta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20250331081658/pages/blog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexYMkHCE98klMeta?.name ?? "blog",
    path: indexYMkHCE98klMeta?.path ?? "/blog",
    meta: indexYMkHCE98klMeta || {},
    alias: indexYMkHCE98klMeta?.alias || [],
    redirect: indexYMkHCE98klMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20250331081658/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93aDGjcaAjcnMeta?.name ?? "catalog-slug",
    path: _91_46_46_46slug_93aDGjcaAjcnMeta?.path ?? "/catalog/:slug(.*)*",
    meta: _91_46_46_46slug_93aDGjcaAjcnMeta || {},
    alias: _91_46_46_46slug_93aDGjcaAjcnMeta?.alias || [],
    redirect: _91_46_46_46slug_93aDGjcaAjcnMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20250331081658/pages/catalog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: temp_catalog_need_32_remove5XJeln5CqpMeta?.name ?? "catalog-temp_catalog_need _remove",
    path: temp_catalog_need_32_remove5XJeln5CqpMeta?.path ?? "/catalog/temp_catalog_need%20_remove",
    meta: temp_catalog_need_32_remove5XJeln5CqpMeta || {},
    alias: temp_catalog_need_32_remove5XJeln5CqpMeta?.alias || [],
    redirect: temp_catalog_need_32_remove5XJeln5CqpMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20250331081658/pages/catalog/temp_catalog_need _remove.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93k0cMYLvbvqMeta?.name ?? "product-slug",
    path: _91_46_46_46slug_93k0cMYLvbvqMeta?.path ?? "/product/:slug(.*)*",
    meta: _91_46_46_46slug_93k0cMYLvbvqMeta || {},
    alias: _91_46_46_46slug_93k0cMYLvbvqMeta?.alias || [],
    redirect: _91_46_46_46slug_93k0cMYLvbvqMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20250331081658/pages/product/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexX6i0yncLh0Meta?.name ?? "product",
    path: indexX6i0yncLh0Meta?.path ?? "/product",
    meta: indexX6i0yncLh0Meta || {},
    alias: indexX6i0yncLh0Meta?.alias || [],
    redirect: indexX6i0yncLh0Meta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20250331081658/pages/product/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93HvJW1aDXKzMeta?.name ?? "realty-slug",
    path: _91_46_46_46slug_93HvJW1aDXKzMeta?.path ?? "/realty/:slug(.*)*",
    meta: _91_46_46_46slug_93HvJW1aDXKzMeta || {},
    alias: _91_46_46_46slug_93HvJW1aDXKzMeta?.alias || [],
    redirect: _91_46_46_46slug_93HvJW1aDXKzMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20250331081658/pages/realty/[...slug].vue").then(m => m.default || m)
  },
  {
    name: index3IFQKeLU9eMeta?.name ?? "sitemap",
    path: index3IFQKeLU9eMeta?.path ?? "/sitemap",
    meta: index3IFQKeLU9eMeta || {},
    alias: index3IFQKeLU9eMeta?.alias || [],
    redirect: index3IFQKeLU9eMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20250331081658/pages/sitemap/index.vue").then(m => m.default || m)
  },
  {
    name: indexwM0HUfeP2CMeta?.name ?? "wish",
    path: indexwM0HUfeP2CMeta?.path ?? "/wish",
    meta: indexwM0HUfeP2CMeta || {},
    alias: indexwM0HUfeP2CMeta?.alias || [],
    redirect: indexwM0HUfeP2CMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20250331081658/pages/wish/index.vue").then(m => m.default || m)
  }
]